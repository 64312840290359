<template>
        <div class="view">
          <v-breadcrumbs>
            <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
              >Home</v-breadcrumbs-item
            >
            <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
            <v-breadcrumbs-item :exact="true" :to="{ name: 'productSpecs', params: {} }">
            Product Specs
            </v-breadcrumbs-item>
            <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
            <v-breadcrumbs-item :to="{ name: 'productSpecsView', params: {} }">
              View Product Specs
            </v-breadcrumbs-item>
          </v-breadcrumbs>

          <v-card v-if="loaded">
            <v-card-title>
              View Product Specs
            </v-card-title>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                                  
          <tr v-if="productSpecs.productId">
            <td max-width="35%" class="text-subtitle-1">Product:</td>
            <td>{{ productSpecs.productName }}</td>
          </tr>

          <tr v-if="productSpecs.elements">
            <td max-width="35%" class="text-subtitle-1">Elements:</td>
            <td>
              {{ getRelationText(productSpecs.elements, "elements", "assettypes") }}
            </td>
          </tr>
          
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>

          <div class="d-flex justify-space-between ma-5">
            <v-btn @click="goBack">Back</v-btn>
            <v-btn @click="edit(id)" color="primary">Edit</v-btn>
            <span></span>
          </div>
      </div>
    </template>

    <script>
    import { getProductSpecs } from "@/services/productSpecs";
    
              import { getAllProductSpecsElements } from "@/services/productSpecsElement";
            

    export default {
      data() {
        return {
            id: '',
            productSpecs: {},
            loaded: false,
            relations: {}
        }
      },
      methods : {
        goBack() {
          this.$router.go(-1);
        },
        edit(id) {
          this.$router.push({ name: "productSpecsEdit", params: { id: id }});
        },
        setInstance() {
          this.setDependencies().then( () => {
            getProductSpecs(this.id)
            .then((response) => {
              this.productSpecs = response.data;
              this.loaded = true;
            })
            .catch( () => {
              this.addErrorMessage("Error loading productSpecs");
              this.loaded = true;
            });
          })
        },
        async setDependencies() {
          
            const params_elements = {
              pageIndex: 1,
              pageSize: 100
            };
            await getAllProductSpecsElements(params_elements)
              .then(response => {
                this.relations.elements = response.data.content;
                return this.relations.elements;
              });
            
        },
        getRelationText(id, relationProperty, relationAttribute) {
          const relationList = this.relations[relationProperty];
          if (relationList) {
            const relationObj = relationList.find(r => r.id == id);
            return relationObj[relationAttribute];
          } else {
            return "";
          }
        }
      },
      created() {
        this.id = this.$route.params.id;
        this.setInstance();
        this.$emit("showParent", false);
      }
    }
    </script>
    