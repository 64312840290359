<template>
  <div class="index container">
    <transition name="fade">
      <router-view @showParent="showParent"> </router-view>
    </transition>

    <div v-if="showParentPage">
      <v-breadcrumbs>
        <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
          >Home</v-breadcrumbs-item
        >
        <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
        <v-breadcrumbs-item
          :exact="true"
          :to="{ name: 'productSpecs', params: {} }"
          active
        >
          Product Specs
        </v-breadcrumbs-item>
      </v-breadcrumbs>

      <div class="d-flex flex-column flex-lg-row">
        <v-text-field
          dense
          v-model="searchTerms"
          @keyup.enter="search"
          @click:clear="clearSearch"
          @click:append="search"
          placeholder="Press enter to begin search"
          append-icon="mdi-magnify"
          outlined
          clearable
          label="Search"
        />
        <v-btn
          class="ml-0 ml-lg-4 mb-2 mb-lg-0 align-self-end align-self-lg-start"
          color="primary"
          :to="{ name: 'productSpecsCreate', params: {} }"
        >
          Create Product Specs
        </v-btn>
      </div>

      <div class="table-container">
        <v-data-table
          :headers="headers"
          :items="productSpecsList"
          :items-per-page="pagination.pageSize"
          :loading="!productSpecsProcessed"
          :server-items-length="pagination.totalSize"
          :page="pagination.current"
          :disable-sort="true"
          item-key="id"
          class="elevation-1"
          @update:options="pageChanged"
        >
          <template v-slot:item.product="{ item }">
            {{ item.productName }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>
            <v-icon small class="mr-2" @click="remove(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </div>
    <confirmation-dialog ref="confirm" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 100,
      },
      productSpecsList: [],
      showParentPage: true,

      headers: [],
      sort: {
        key: null,
      },
      searchTerms: "",
    };
  },
  computed: {
    ...mapGetters("productSpecs", ["productSpecsProcessed", "getProductSpecs"]),
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("productSpecs", ["deleteProductSpecs", "fetchProductSpecs"]),
    view(item) {
      this.$router.push({ name: "productSpecsView", params: { id: item.id } });
    },
    edit(item) {
      this.$router.push({ name: "productSpecsEdit", params: { id: item.id } });
    },
    async remove(item) {
      let confirm = await this.$refs.confirm.open(
        "Confirm Delete",
        "Do you want to delete this productSpecs?"
      );
      if (confirm) {
        this.deleteProductSpecs(item.id)
          .then(() => {
            this.addSuccessMessage(
              "productSpecs was deleted successfully"
            );
            this.getResources();
          })
          .catch(() => {
            this.addErrorMessage("Server response with error ");
          });
      }
    },
    getResources() {
      const params = {
        page:
          this.pagination.pageIndex && this.pagination.pageIndex > 0
            ? this.pagination.pageIndex - 1
            : 0,
        size: this.pagination.pageSize,
        'product.name': this.searchTerms,
      };
      this.fetchProductSpecs(params)
        .then((response) => {
          if (response) {
            this.pagination.totalSize = response.totalElements;
            this.productSpecsList = response.content;

            this.prepHeaders();
          }
        })
        .catch((error) => {
          this.addErrorMessage("Server response with error " + error);
        });
    },
    showParent(show) {
      this.showParentPage = show;
    },
    pageChanged(pagination) {
      this.pagination.pageIndex = pagination.page;
      this.pagination.pageSize = pagination.itemsPerPage;

      this.getResources();
    },
    search() {
      this.getResources();
    },
    clearSearch(){
      this.searchTerms = null;
      this.getResources();
    },
    printElementSpec(element){
      if(element.minPercent && !element.maxPercent){
        return `${element.minPercent}+`
      }
      if(!element.minPercent && element.maxPercent){
        return `<${element.maxPercent}`
      } 
      else if(element.minPercent && element.maxPercent){
        return `${element.minPercent} - ${element.maxPercent}`
      }
    },
    prepHeaders() {
      this.headers = []
      this.headers.push({
          text: "Product",
          value: "productName",
          align: "start",
          sortable: true,
        })

      const uniqueElements = this.productSpecsList.reduce((set, p) => {
        p.elements.forEach((element) => {
          const symbol = element.element.symbol;
          set.add(symbol);

          p[symbol] = this.printElementSpec(element);
        });

        return set;
      }, new Set());

      uniqueElements.forEach((symbol) => {
        this.headers.push({
          text: symbol,
          value: symbol,
          align: "start",
          sortable: true,
        });
      });
      this.headers.push({
        text: "Actions",
        value: "actions",
        align: "end",
        sortable: false,
      });
    },
  },
  watch: {
    "pagination.numberRegisterForPage": function () {
      this.pagination.current = -1;
    },
  },
  beforeRouteUpdate(to, from, next) {
    const toDepth = to.path.split("/").length;
    const fromDepth = from.path.split("/").length;
    this.showParentPage = toDepth < fromDepth;
    if (this.showParentPage) {
      this.getResources();
    }
    next();
  },
};
</script>
