<template>
      <div class="productSpecsEdit">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'productSpecs', params: {} }">
          Product Specs
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'productSpecsEdit', params:{id: id} }">
            Edit Product Specs
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Edit Product Specs</v-card-title>
          <v-card-text>
            <productSpecs-form :id="id"></productSpecs-form>
          </v-card-text>
        </v-card>
      </div>
    </template>

    <script>
    import ProductSpecsForm from './ProductSpecsForm.vue';

    export default {
      name: 'productSpecs',
      data () {
        return {
          id: null
        }
      },
      components: {
        ProductSpecsForm
      },
      created () {
          this.id = this.$route.params.id;
          this.$emit("showParent", false);
      }
    }
    </script>
    